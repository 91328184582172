import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GlobalConstants } from '../../../model/global-constants';

@Component({
  selector: 'app-roi-calculation',
  templateUrl: './roi-calculation.component.html',
  styleUrls: ['./roi-calculation.component.css']
})
export class RoiCalculationComponent implements OnInit {

  @Output() on_values_changed : EventEmitter<any> = new EventEmitter();
  reduce_medication_spend_assump : number = 24;
  additional_healthcare_cost_assump : number = 7;
  average_spend_per_claimant_per_year : number = 26000;
  average_annual_ai_medication_cost : number = 50000;
  average_rebate_rate_percent : number = 30;
  average_annual_ai_medication_cost_after_rebates : number = this.average_annual_ai_medication_cost * (1 - (this.average_rebate_rate_percent / 100));
  additional_healthcare_cost :  number = this.average_annual_ai_medication_cost_after_rebates * (this.additional_healthcare_cost_assump / 100);
  total_healthcare_cost : number = this.average_annual_ai_medication_cost_after_rebates + this.additional_healthcare_cost;
  medication_cost_reduction : number = this.average_annual_ai_medication_cost_after_rebates * (this.reduce_medication_spend_assump / 100);
  mymee_program_cost_per_client : number = GlobalConstants.initial_mymee_program_cost_per_client;
  completers : number = 0;
  enrolees : number = 0;
  mymee_program_cost : number = this.mymee_program_cost_per_client * this.completers;

  expected_medication_reduction_savings_to_bbva : number = 0;
  estimated_total_savings : number = this.expected_medication_reduction_savings_to_bbva * 1;

  basic_uncertainty : number = 0.2;
  total_net_savings : number;
  estimated_total_savings_rounded_lower : number;
  estimated_total_savings_rounded_upper : number;
  total_net_savings_rounded_lower : number;
  total_net_savings_rounded_upper : number;
  roi_total_lower : number;
  roi_total_upper : number;
  roi : number = 0;
  roi_total : number = 0;
  round_to : number = 100000;

  constructor() { }

  ngOnInit() {
  }

  round(i, n) {
    return Math.round(i / n) * n;
  }

  recalculate(program_enrolled) {
    this.average_annual_ai_medication_cost_after_rebates = this.average_annual_ai_medication_cost * (1 - (this.average_rebate_rate_percent / 100));
    this.medication_cost_reduction = this.average_annual_ai_medication_cost_after_rebates * (this.reduce_medication_spend_assump / 100);
    this.mymee_program_cost = GlobalConstants.initial_mymee_program_cost_per_client * program_enrolled;
    this.additional_healthcare_cost = this.average_annual_ai_medication_cost_after_rebates * (this.additional_healthcare_cost_assump / 100);
    this.total_healthcare_cost = this.average_annual_ai_medication_cost_after_rebates + this.additional_healthcare_cost;
    this.expected_medication_reduction_savings_to_bbva = program_enrolled * this.medication_cost_reduction;
    this.estimated_total_savings = program_enrolled * (this.medication_cost_reduction + this.additional_healthcare_cost);

    this.enrolees = program_enrolled;
    this.roi = this.expected_medication_reduction_savings_to_bbva / this.mymee_program_cost;
    this.roi_total = this.estimated_total_savings / this.mymee_program_cost;

    this.roi_total_lower = this.roi_total * (1 - this.basic_uncertainty);
    this.roi_total_upper = this.roi_total * (1 + this.basic_uncertainty);

    this.estimated_total_savings_rounded_lower = this.round(this.estimated_total_savings * (1 - this.basic_uncertainty), this.round_to);
    this.estimated_total_savings_rounded_upper = this.round(this.estimated_total_savings * (1 + this.basic_uncertainty), this.round_to);

    this.total_net_savings = this.estimated_total_savings - this.mymee_program_cost;
    this.total_net_savings_rounded_lower = this.round((this.estimated_total_savings - this.mymee_program_cost) * (1 - this.basic_uncertainty), this.round_to);
    this.total_net_savings_rounded_upper = this.round((this.estimated_total_savings - this.mymee_program_cost) * (1 + this.basic_uncertainty), this.round_to);
  }

  on_input_change() {
    this.on_values_changed.emit();
  }


}
