import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-funnel-graph',
  templateUrl: './funnel-graph.component.html',
  styleUrls: ['./funnel-graph.component.css']
})
export class FunnelGraphComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

  recalculate_graph(total_employees : number, meet_eligibility : number, become_in_program : number, complete_program : number)
  {
    const canvas_width = 400;
    const canvas_height = 400;

    let parameters = [total_employees, meet_eligibility, become_in_program, complete_program];

    let max = parameters.reduce((a, b) => Math.max(a,b));
    let section_height = canvas_height / parameters.length;
    let width_percentages = parameters.map(function (d, i) {
      return d / max;
    });

    let canvas = <HTMLCanvasElement> document.getElementById('funnel-graph-canvas');
    if(canvas.getContext) {
      let ctx = canvas.getContext('2d');

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      var prev_coordinates = null;
      
      for(var i = 0; i < width_percentages.length; i++) {
        ctx.beginPath();

        let current_width = width_percentages[i] * canvas_width;
        let next_width_percentage = (i + 1 < width_percentages.length) ? width_percentages[i + 1] : null;
        let next_width = (next_width_percentage != null) ? next_width_percentage * canvas_width : current_width;

        let top_y = (i == 0) ? 0.0 : prev_coordinates.bl_y;
        let bottom_y = (i == 0) ? section_height : prev_coordinates.bl_y + section_height;

        let top_left_x = (i == 0) ? 0.0 : prev_coordinates.bl_x;
        let top_left_y = top_y;
        let top_right_x = (i == 0) ? current_width : prev_coordinates.br_x;
        let top_right_y = top_y; 
        
        let bottom_right_x = (next_width_percentage != null) ? canvas_width - ((canvas_width - next_width) / 2) : top_right_x;
        let bottom_right_y = bottom_y;
        let bottom_left_x = (next_width_percentage != null) ? (canvas_width - next_width) / 2 : top_left_x;
        let bottom_left_y = bottom_y;

        ctx.moveTo(top_left_x, top_left_y);
        ctx.lineTo(top_right_x, top_right_y);
        ctx.moveTo(top_right_x, top_right_y);
        ctx.lineTo(bottom_right_x, bottom_right_y);
        ctx.moveTo(bottom_right_x, bottom_right_y);
        ctx.lineTo(bottom_left_x, bottom_left_y);
        ctx.moveTo(bottom_left_x, bottom_left_y);
        ctx.lineTo(top_left_x, top_left_y);
        ctx.stroke();

        prev_coordinates = {
          tl_x:top_left_x,
          tl_y:top_left_y,
          tr_x:top_right_x,
          tr_y:top_right_y,
          br_x:bottom_right_x,
          br_y:bottom_right_y,
          bl_x:bottom_left_x,
          bl_y:bottom_left_y
        };
      }
    }
  }
}
