import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { MaterialModule } from './modules/material/material.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InputTableComponent } from './components/views/input-table/input-table.component';
import { PercentPipe } from './pipes/percent.pipe';
import { RoiCalculationComponent } from './components/views/roi-calculation/roi-calculation.component';
import { PaymentMilestonesComponent } from './components/views/payment-milestones/payment-milestones.component';
import { NavbarComponent } from './components/views/navbar/navbar.component';
import { FunnelGraphComponent } from './components/views/funnel-graph/funnel-graph.component';
import { FunnelGraphDialogComponent } from './components/dialogs/funnel-graph-dialog/funnel-graph-dialog.component';
import { RequestDemoDialogComponent } from './components/dialogs/request-demo-dialog/request-demo-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    InputTableComponent,
    PercentPipe,
    RoiCalculationComponent,
    PaymentMilestonesComponent,
    NavbarComponent,
    FunnelGraphComponent,
    FunnelGraphDialogComponent,
    RequestDemoDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule
  ],
  providers: [{provide:LocationStrategy, useClass:HashLocationStrategy}],
  bootstrap: [AppComponent],
  entryComponents:[FunnelGraphDialogComponent, RequestDemoDialogComponent]
})
export class AppModule { }
