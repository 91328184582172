import { Component, ViewChild, AfterViewInit} from '@angular/core';
import { InputTableComponent } from './components/views/input-table/input-table.component';
import { RoiCalculationComponent } from './components/views/roi-calculation/roi-calculation.component';
import { PaymentMilestonesComponent } from './components/views/payment-milestones/payment-milestones.component';
import { MatDialog } from '@angular/material/dialog';
import { FunnelGraphDialogComponent } from './components/dialogs/funnel-graph-dialog/funnel-graph-dialog.component';
import { RequestDemoDialogComponent } from './components/dialogs/request-demo-dialog/request-demo-dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements AfterViewInit{

  @ViewChild('funnel', {static:false}) funnel : InputTableComponent;

  @ViewChild('roiCalculation', {static:false}) roi_calculation : RoiCalculationComponent;

  @ViewChild('paymentMilestones', {static:false}) payment_milestones : PaymentMilestonesComponent;

  constructor(public dialog : MatDialog){

  }

  ngAfterViewInit() {
    this.recalculate_all();
  }

  on_input_table_changed() {
    this.recalculate_all();
  }

  on_roi_table_changed() {
    this.recalculate_all();
  }

  on_view_graph() {
    this.dialog.open(FunnelGraphDialogComponent, {
      width:'70%',
      height: '90%',
      data: {
        total_employees: this.funnel.input_map.total_members_on_health_plan_millions * 1000000,
        respondents_meet_eligibility: this.funnel.calc_num_members_respondents_meet_eligibility,
        onboarding_success_rate: this.funnel.calc_num_members_onboarding_success_rate,
        complete_program: this.funnel.calc_num_members_complete_program
      }
    });
  }

  on_request_demo() {
    this.dialog.open(RequestDemoDialogComponent, {
      width:'40%',
      height:'70%'
    });
  }

  private recalculate_all() {
    this.roi_calculation.recalculate(this.funnel.calc_num_members_onboarding_success_rate);
    
    this.funnel.recalculate_values();

    this.payment_milestones.recalculate_milestones(this.roi_calculation.mymee_program_cost);
  }
}


