import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-payment-milestones',
  templateUrl: './payment-milestones.component.html',
  styleUrls: ['./payment-milestones.component.css']
})
export class PaymentMilestonesComponent implements OnInit {

  @Output() on_table_changed : EventEmitter<any> = new EventEmitter();

  milestone_1 : number = 0;
  milestone_2 : number = 0;
  milestone_3 : number = 0;
  milestone_4 : number = 0;

  milestone_1_percentage : number = 0.1;
  milestone_2_percentage : number = 0.2;
  milestone_3_percentage : number = 0.2;
  milestone_4_percentage : number = 0.5;

  constructor() { }

  ngOnInit() {
  }

  on_input_changed() {
    this.on_table_changed.emit();
  }

  recalculate_milestones(mymee_program_cost) {
    this.milestone_1 = mymee_program_cost * this.milestone_1_percentage;
    this.milestone_2 = mymee_program_cost * this.milestone_2_percentage;
    this.milestone_3 = mymee_program_cost * this.milestone_3_percentage;
    this.milestone_4 = mymee_program_cost * this.milestone_4_percentage;
  }
}
