import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-input-table',
  templateUrl: './input-table.component.html',
  styleUrls: ['./input-table.component.css']
})
export class InputTableComponent implements OnInit {

  @Output() on_table_changed : EventEmitter<any> = new EventEmitter();

  @Output() on_view_graph : EventEmitter<any> = new EventEmitter();

  non_responders_default : number = 66;
  onboarding_attempt_rate_default : number = 50;
  onboarding_success_rate_default : number = 62;

  input_map = {
    total_members_on_health_plan_millions : 1,
    total_members_on_health_plan_funnel_assump : 100,
    total_members_advertised_funnel_assump : 100,
    non_responders_funnel_assump : this.non_responders_default,
    respondents_meet_eligibility_funnel_assump : 0.75,
    onboarding_attempt_rate_funnel_assump : this.onboarding_attempt_rate_default,
    onboarding_success_rate_funnel_assump : this.onboarding_success_rate_default,
    complete_program_funnel_assump : 82
  }

  // Calculated
  calc_num_members_respondents_meet_eligibility : number = 0;
  calc_num_members_advertised : number = 0;
  calc_num_non_responders : number = 0;
  calc_num_members_onboarding_attempt_rate : number = 0;
  calc_num_members_onboarding_success_rate : number = 0;
  calc_num_members_complete_program : number = 0;
  calc_num_members_reduce_medication_spend : number = 0;
  calc_overall_savings : number = 0;

  constructor() { }

  ngOnInit() {
    this.recalculate_values();
  }

  // Input changes
  on_input_change() {
    this.on_table_changed.emit();   
  }

  on_input_focus_lost() {
    this.on_table_changed.emit();
  }

  on_clamped_value_changed(value, event) {
    this.input_map[value] = this.clamp(event.target.value, 0, 100);
    this.on_table_changed.emit();
  }

  on_clamped_input_focus_lost(input_map_key, event) {
    this.input_map[input_map_key] = this.clamp(event.target.value, 0, 100);
    event.target.value = this.input_map[input_map_key]; 
    this.on_table_changed.emit();
  }

  on_view_graph_pressed() {
    this.on_view_graph.emit();
  }

  recalculate_values() {

    this.calc_num_members_respondents_meet_eligibility = this.input_map['total_members_on_health_plan_millions'] * 1000000 * (this.input_map['respondents_meet_eligibility_funnel_assump'] * 0.01);

    this.calc_num_non_responders = this.calc_num_members_respondents_meet_eligibility * (this.input_map['non_responders_funnel_assump'] * 0.01);

    this.calc_num_members_advertised = this.calc_num_non_responders * (this.input_map['total_members_advertised_funnel_assump'] * 0.01);

    this.calc_num_members_onboarding_attempt_rate = this.calc_num_members_advertised * (this.input_map['onboarding_attempt_rate_funnel_assump'] * 0.01);
    this.calc_num_members_onboarding_success_rate = this.calc_num_members_onboarding_attempt_rate * (this.input_map['onboarding_success_rate_funnel_assump'] * 0.01);

    this.calc_num_members_complete_program = this.calc_num_members_onboarding_success_rate * (this.input_map['complete_program_funnel_assump'] * 0.01);

    this.calc_num_members_reduce_medication_spend = this.calc_num_members_complete_program;

  }

  private clamp(num, min, max) {
    return num <= min ? min : num >= max ? max : num;
  }
}
