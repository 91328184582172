import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import D3Funnel from 'd3-funnel';

@Component({
  selector: 'app-funnel-graph-dialog',
  templateUrl: './funnel-graph-dialog.component.html',
  styleUrls: ['./funnel-graph-dialog.component.css']
})
export class FunnelGraphDialogComponent implements OnInit {


  constructor(public dialog_ref: MatDialogRef<FunnelGraphDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    this.draw_graph();
  }

  on_dismiss_pressed() {
    this.dialog_ref.close();
  }

  private draw_graph() {
    const data = [

      { label: 'Plan Members', value: Math.round(this.data.total_employees), backgroundColor: '#1d3b75' },
      { label: 'Respondents Meet Eligibility', value: Math.round(this.data.respondents_meet_eligibility), backgroundColor: '#305191' },
      { label: 'Invited to enroll', value: Math.round(this.data.calc_num_members_advertised), backgroundColor: '#4869ab'},
      { label: 'Onboarding Successes', value: Math.round(this.data.onboarding_success_rate), backgroundColor: '#4869ab'},
      { label: 'Program Completions', value: Math.round(this.data.complete_program), backgroundColor: '#6887c4'},
    ];

    const options = {
      block: {
          dynamicHeight: true,
          minHeight: 30,
          
          
      },
      label: {

      }
    };
    const chart = new D3Funnel('#funnel');
    chart.draw(data, options);
  }

}
