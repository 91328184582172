import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'

@Component({
  selector: 'app-request-demo-dialog',
  templateUrl: './request-demo-dialog.component.html',
  styleUrls: ['./request-demo-dialog.component.css']
})
export class RequestDemoDialogComponent implements OnInit {

  public form_group : FormGroup;

  constructor(private form_builder : FormBuilder) { }

  ngOnInit() {
    this.form_group = this.form_builder.group ({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      company: ['', Validators.required],
      companyURL: ['', Validators.required]
    });
  }

}
